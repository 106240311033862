import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Button, Title, Text, Box } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Contact from "../sections/common/Contact";
import { device } from "../utils";

import "../assets/fonts/icon-font/css/style.css";

const WorkSingle = () => {
  return (
    <>
      <PageWrapper darkTheme>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">development</Text>
                <Title variant="secSm" className="my-4">
                  Grub Run{" "}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  GrubRun is an interactive web application that chooses restaurants at random based the user's selected location and radius. Users
                  are able to browse through different restaurants and save restaurants they found interesting to their account. This web application
                  is built using HTML, CSS, Javascript, React, and Firebase.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Role</Text>
                <Title variant="cardBig" className="mt-3">
                  Front-End Developer
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Date</Text>
                <Title variant="cardBig" className="mt-3">
                  Sep 2018 - Dec 2018
                </Title>
              </Col>
              <Col lg="4">
                <Link
                  to="https://info340c-au18.github.io/stage-0-topic-proposal-magalj/" target="_blank"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={1000}
                >
                  <Button arrowRight>Live Work</Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Section className="mt-lg-5">
          <Container>
            <Row style={{marginTop: -20}}>
              <Col xs="8" class="mx-auto" className="mb-2" style={{margin: "auto"}}>
                  <img src={"https://res.cloudinary.com/ryandaaang/image/upload/v1619217494/Grub%20Run/GrubRun_Logo.png"} alt="grubrun logo" className="img-fluid w-100" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4" style={{marginTop: -60}}>
              Project Process
            </Title>
                <Title variant="cardBig" className="mb-4" style={{marginTop: -30}}>
                  01. Overall Functionality
                </Title>
                <Text variant="p" className="mb-4">
                  Our web application has two significant interactive features. The first feature is the ability to view a list of 
                  random restaurants in a given location and radius based on input given by the user. This requires successful implementation
                  of
                  <a href="https://www.yelp.com/developers/documentation/v3" target="_blank"> Yelp's API </a>
                  and other various programming functions. The second feature is the ability for a user to create an account and save any
                  restaurants they like to a favorites list.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  02. Data Persistence
                </Title>
                <Text variant="p" className="mb-4">
                <a href="https://firebase.google.com/" target="_blank">Firebase </a>
                  is used for user authentication and as a cloud-hosted database that grants users the ability to save their favorite restaurants
                  to a user account. This allows for easy access across different browsers and devices.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  03. Responsiveness and Accessibility
                </Title>
                <Text variant="p" className="mb-4">
                  Our web application follows a mobile-first design approach which aims to create better experiences for users by starting the
                  design process from the smallest screens. This helps ensure the user experience to be seamless on any devices. Our application
                  is accessible and supports non-visual devices like screen readers. React components are rendered to semantic HTML and visual
                  elements have an appropriate <code>alt</code> or <code>aria</code> attributes.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  04. User Experience
                </Title>
                <Text variant="p" className="mb-4">
                  We made use of appropriate fonts, colors, page layouts, and images to create an intuitive and engaging user experience.
                  We included a section that goes over every feature of our web application to make it easier for users to engage with our
                  solution. We made sure to have a good balance of simplicity and usability that aims to reduce any points of confusion.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  05. Implementation
                </Title>
                <Text variant="p" className="mb-4">
                  Our web application is developed using the React library and contains code that is readable and understandable. We
                  appropriately organized our code into components and separate files. Whenever our code becomes too long or difficult
                  to understand, we included comments and documentation explaining how it works.
                </Text>
                <Title variant="cardBig" className="mb-4" style={{marginTop: 50}}>
                  06. Outcomes
                </Title>
                <Text variant="p" className="mb-4">
                  Through this project, I gained a deep understanding about the fundamentals of web development. I have acquired the skills
                  and techniques necessary for creating sophisticated and accessible interactive web applications. Working on this project
                  in stages allowed for iteration and improvement of skills as the project went from one stage to the next. I now have the
                  foundation for independently learning new tools and techniques in the ever-changing world of web development.
                </Text>
          </Container>
        </Section>
        <Section mt="5%" bg="dark">
          <Container>
            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="/watch-it">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                  `}
                >
                  Watch It
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  );
};
export default WorkSingle;
